.info-container__button {
	position: fixed;
	left: 0;
	padding: 0 30px;
	width: 100%;
	bottom: 25px;
	max-width: 100%;
	display: block;
}

.info-container__title,
.info-container__admin-title {
	margin-bottom: 20px;
}

.info-container__title h2 {
	font-size: 2rem;
}

.info-container__sub-title {
	margin-top: 5px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.info-container__sub-title p {
	color: #858585;
}

.info-container__text {
	margin-bottom: 20px;
}

.info-container__text p {
	text-align: justify;
	font-weight: 600;
}

.info-container__extra h2 {
	font-size: 1.5rem;
}

.info-container__extra-title {
	margin-bottom: 20px;
}

.info-container__specific-info {
	display: flex;
	margin-bottom: 5px;
	align-items: center;
}

.info-container__specific-info p {
	margin-left: 5px;
}

.info-container__comuna {
	font-weight: 700;
}

.info-container__button-separator {
	margin-top: 10px;
}

@media (min-width: 992px) {
	.info-container__button {
		display: block;
		position: static;
		width: 100%;
		padding: 0;
		margin-top: 75px;
	}

	.info-container__text p {
		text-align: left;
		font-weight: 600;
	}

	.info-container__sub-title {
		margin-top: 5px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
}
