.main-navigation__menu-btn {
	width: 3rem;
	height: 2rem;
	background: transparent;
	border: none;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	cursor: pointer;
}

.main-navigation__span-1,
.main-navigation__span-2,
.main-navigation__span-3 {
	display: block;
	width: 30px;
	height: 3px;
	border-radius: 10px;
	background: rgb(255, 255, 255);
}

.main-navigation__button-profile {
	display: none;
}

.drawer__menu {
	padding: 20px 30px;
}

.drawer__menu-logotipo {
	margin: 0 auto;
	width: 200px;
}

.drawer__menu-logotipo img {
	width: 100%;
}

.drawer__menu-nav {
	margin-top: 20px;
}

.drawer__menu-nav-margin {
	margin-top: 10px;
}

.drawer__menu-profile-details {
	display: flex;
	align-items: center;
}

.drawer__menu-profile-hr {
	border: none;
	height: 1px;
	margin-top: 20px;
	background-color: #d3131c;
}

.drawer__menu-profile-initials {
	background-color: #d3131c;
	border-radius: 100%;
	height: 35px;
	width: 35px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	font-weight: bold;
}

.drawer__menu-profile-user {
	margin-left: 10px;
}

.drawer__menu-profile-user h5 {
	font-size: 16px;
	margin: 0;
}

.drawer__menu-profile-user p {
	font-size: 12px;
}

.main-navigation__logotipo {
	display: none;
}

.main-navigation__logotipo img {
	width: 100%;
}

.profile__nav-button {
	display: flex;
	padding: 0 0.8rem;
	justify-content: center;
	align-items: center;
}

.profile__nav-button p {
	font-weight: 700;
}

.profile__nav-button div {
	display: flex;
	justify-content: center;
	align-items: center;
}

.main-navigation__button-container {
	width: 70%;
	display: flex;
	justify-content: flex-end;
}

.main-navigation__button {
	min-width: 100px;
}

.main-navigation__button-resume {
	display: none;
}

.main-navigation__button-publicar {
	width: 125px;
	margin-right: 15px;
}

@media (min-width: 768px) {
	.main-navigation__menu-btn {
		display: none;
	}

	.main-navigation__active {
		display: none;
	}

	.main-navigation__logotipo {
		display: flex;
		width: 155px;
		justify-content: center;
		align-items: center;
	}

	.main-navigation__header-nav {
		display: block;
	}

	.main-navigation__logo-link {
		display: block;
	}

	.main-navigation__drawer-nav {
		display: none;
	}

	.main-navigation__button-container {
		width: auto;
	}

	.main-navigation__button,
	.main-navigation__button-profile {
		width: auto;
		display: inline-block;
		min-width: 100px;
	}

	.main-navigation__button-profile {
		margin-left: 10px;
	}

	.main-navigation__button-resume {
		display: inline-block;
		width: 130px;
		margin-right: 15px;
	}
}
