.filter {
    padding: 15px 1.5rem;
    padding-bottom: 25px;
}

.filter__helper-text {
    margin: 15px 0;
    text-align: center;
    font-weight: 600;
}

.filter hr {
    border: none;
    height: 1px;
    margin: 15px 0;
    background-color: #D3131C;
}