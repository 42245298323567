.menu {
    display: none;
    position: fixed;
    top: 50px;
    right: 30px;
    padding: 0.5rem;
    background: #FAFAFA;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    z-index: 100;
}

.menu ul {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style: none;
}

.menu li {
    width: 150px;
    margin: 5px 0;
}

.menu li a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
    font-weight: 600;
}

.Icon__container {
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu li button {
    width: 100%;
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    cursor: pointer;
    font-family: inherit;
    font-weight: 600;
    font-size: 1rem;
    padding: 0;
}

@media (min-width: 768px) {
    .menu {
        display: block;
    }
}