.side-drawer {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  height: auto;
  width: 100%;
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 16px rgb(0 0 0 / 40%);
}


@media (min-width: 768px) {
  .side-drawer {
    left: calc(50% - 15rem);
    top: 3rem;
    width: 30rem;
  }
}