.applicantsList_page {
	padding: 4rem 30px;
	max-width: 450px;
	margin: 0 auto;
}

.applicantsList__header {
	margin-bottom: 20px;
	flex-direction: column;
}

.applicantsList__header h2 {
	font-size: 2rem;
}

.applicantsList__header-btn {
	position: relative;
}

.applicant__grid-template {
	height: auto;
	overflow: auto;
	padding: 0.5rem 0;
	display: grid;
	grid-template-columns: repeat(1, minmax(0, 1fr));
	grid-auto-rows: 1fr;
	grid-column-gap: 20px;
	grid-row-gap: 20px;
}

@media (min-width: 768px) {
	.applicantsList_page {
		max-width: 700px;
	}

	.applicant__grid-template {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}

	.applicantsList__header {
		display: flex;
		justify-content: center;
        flex-direction: row;
        position: relative;
	}
	.applicantsList__header-btn {
		position: absolute;
		left: 0;
	}
}

@media (min-width: 992px) {
	.applicantsList_page {
		max-width: 1100px;
	}

	.applicant__grid-template {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
}
