.offer-detail__container {
	padding: 0 30px;
	padding-top: 4rem;
	max-width: 1100px;
	width: 100%;
	margin: 0 auto;
	padding-bottom: 70px;
	position: relative;
}

@media (min-width: 768px) {
	.offer-detail__container {
		max-width: 700px;
	}
}

@media (min-width: 992px) {
	.offer-detail__container {
		max-width: 1100px;
	}
}
