.card__link {
    text-decoration: none;
    color: black;
    cursor: pointer;
}

.card-container,
.card-element-list {
    background: #FAFAFA;
    border: 1px solid #F2F2F2;
    box-sizing: border-box;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    padding: 10px;
}

.card-element-list {
    margin-bottom: 10px;
}

.card-container__text h6,
.card-element-list__text-name h6 {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.2rem;
    font-weight: 700;
}

.card-element-list__text-name h6 {
    text-align: initial;
}

.card-container__text p {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 1rem*2rem*6;
    white-space: normal;
    font-size: 0.9rem;
    margin-top: 5px;
    min-height: 55px;
}

.card-container__info,
.card-container__info_experience {
    display: flex;
}

.card-container__info {
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin-top: 10px;
    min-height: 30px;
}

.card-container__info_experience {
    justify-content: flex-end;
}

.card-container__info-details {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
}

.card-container__info_item {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.card-container__info_item p {
    margin-right: 5px;
    font-size: 0.9rem;
    font-weight: 700;
}

.card-container__info_time img,
.card-container__info_views img {
    width: 20px;
    align-self: self-start;
    margin: 0;
}

.card-container__info_views img {
    height: 20px;
}


.card-container__info_whitout_experience {
    background-color: #D31313;
    width: 100px;
    padding: 5px 0;
    color: white;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-container__info_whitout_experience p {
    font-size: 0.9rem;
    font-weight: 600;
    text-align: center;
}




.card-element-list__tags {
    display: flex;
    margin-top: 10px;
}

.tag-detail {
    background-color: #0EA301;
    border-radius: 4px;
    padding: 5px;
    margin-right: 5px;
    color: white;
}

.tag-detail p {
    font-weight: 600;
}

.tag-detail__false {
    background-color: #D3131C;
}