.profile_page {
	padding: 4rem 30px;
	padding-bottom: 1.5rem;
	max-width: 350px;
	margin: 0 auto;
	position: relative;
}

.profile__container {
	display: grid;
	grid-template-columns: repeat(1, minmax(0, 1fr));
	grid-auto-rows: 0fr;
	grid-column-gap: 30px;
	justify-items: unset;
}

@media (min-width: 768px) {
	.profile_page {
		max-width: 1100px;
	}

	.profile__container {
		justify-items: center;
	}

	.profile__form {
		width: 350px;
		max-width: unset;
		min-width: 350px;
	}
}

@media (min-width: 992px) {
	.profile_page {
		max-width: 1100px;
	}
}
