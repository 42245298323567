.resume__container {
    text-align: center;
    padding: 0rem 1rem;
    margin: 0 auto;
    margin-top: 60px;
}

.resume__title {
    margin-bottom: 20px;
}

.resume__title h2 {
    font-size: 2rem;
}

.resume__body {
    background-color: #F0F0F0;
    border-radius: 4px;
    padding: 10px;
}

@media (min-width: 768px) {
    .resume__container {
        max-width: 700px;
    }
}


@media (min-width: 992px) {
    .resume__container {
        max-width: 1100px;
    }
}