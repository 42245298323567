.lost-page {
    text-align: center;
    padding: 7rem 2rem;
}

.lost-page h1 {
    font-size: 4rem;
}

.lost-page h3 {
    font-size: 1.5rem;
}

.lost-page p {
    font-size: 1rem;
    font-weight: 600;
}