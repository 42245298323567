.no-offers_container {
    text-align: center;
    padding: 0rem 2rem;
    margin-top: 50px;
}

.no-offers_container h1 {
    font-size: 3rem;
    margin-bottom: 15px;
}

.no-offers_container p {
    font-size: 1rem;
    font-weight: 600;
}

.ofertas__filters {
    margin-bottom: 30px;
}

.offer__header {
    margin-bottom: 20px;
}

.offer__header h2 {
    font-size: 1.8rem;
}

.offer_grid-template {
    height: auto;
    overflow: auto;
    padding: 0.5rem 0;
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-auto-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}

.offer__page {
    padding: 2rem 20px;
    max-width: 450px;
    margin: 0 auto;
    margin-top: 3rem;
}

.offer__end-infite-scroll {
    grid-column: 1/-1;
    align-self: center;
    font-size: 1.2rem;
    text-align: center;
}

@media (min-width: 768px) {
    .offer__page {
        max-width: 700px;
    }

    .offer_grid-template {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}


@media (min-width: 992px) {
    .offer__page {
        max-width: 1100px;
    }

    .offer_grid-template {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}