.main-header {
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background: #1A1C2C;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.20);
  padding: 0 1rem;
  z-index: 5;
  box-sizing: border-box;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .main-header {
    padding: 0 30px;
    justify-content: space-between;
  }
}