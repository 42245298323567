.login {
	padding: 0rem 10px;
	padding-top: 50px;
	padding-bottom: 1.5rem;
	box-sizing: border-box;
	max-width: 350px;
	margin: 0 auto;
	height: 100vh;
	width: 100%;
}

.login__logotipo {
	width: 100%;
	max-width: 450px;
	margin: 0 auto;
	margin-bottom: 20px;
}

.login__logotipo img {
	width: 100%;
}

.login__footer {
	margin-top: 50px;
}

.login__footer-link {
	text-align: center;
	margin-bottom: 1rem;
}

.login__footer-link a,
.login__footer-link a:visited {
	color: #d3131c;
}

.login__error {
	margin: 0 auto;
	margin-top: 50px;
	text-align: center;
	max-width: 250px;
}

.login__error p {
	font-weight: 600;
	color: #d3131c;
}

@media (min-width: 768px) {
	.login {
		padding: 0rem 30px;
        padding-bottom: 150px;
		box-sizing: border-box;
		max-width: unset;
		margin: 0 auto;
		height: 100vh;
		width: 100%;
		background-color: #e5e5e5;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.login__container {
		background: #ffffff;
		box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
		border-radius: 4px;
		padding: 30px;
	}
}
