.resume__info-general{
    margin-bottom: 30px;
}

.resume__info-title{
    text-align: initial;
    margin-bottom: 20px;
}

.resume__info-specific{
    text-align: initial;
    display: flex;
    font-weight: 600;
    margin-bottom: 10px
}
.resume__info-specific p{
    font-size: medium;
    font-weight: 600;
    margin-right: 10px;
    min-width: 200px;
}