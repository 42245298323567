* {
  box-sizing: border-box;
  outline: none;
}

body {
  background-color: #FFFFFF;
  overscroll-behavior-y: contain;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Rajdhani', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

p {
  font-weight: 500;
}

.container {
  padding: 2rem 20px;
  max-width: 450px;
  margin: 0 auto;
}

.nav-container {
  padding: 2rem 20px;
  max-width: 450px;
  margin: 0 auto;
  margin-top: 3rem;
}

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button__top-margin {
  margin-top: 10px;
}

.button_link :hover {
  color: #810c12;
  border: 1px solid black;
}

span {
  font-weight: bold;
}

.ladingPage__logotipo {
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.ladingPage__logotipo img {
  width: 100%;
}

.loadingSpinner--center {
  background-color: #F6F6F6;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide-in-left-enter {
  transform: translateY(-100%);
}

.slide-in-left-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  transform: translateY(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  transform: translateY(-100%);
  opacity: 0;
  transition: all 200ms;
}