.goBack__container {
}
.goBack__btn {
	border: none;
	border-radius: 100px;
	padding: 0;
	cursor: pointer;
	padding: 10px 10px;
	padding-left: 0;
	background-color: white;
}
.goBack__btn-content {
	display: flex;
	justify-content: center;
	align-items: center;
}

.goBack__btn-content img {
	margin-right: 5px;
}

.goBack__btn-content p {
	font-weight: 600;
	font-size: 14px;
}
