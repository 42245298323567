.output {
	width: 100%;
	border-radius: 4;
	background-color: #f1f1f1;
	padding: 5px 10px;
	min-height: 55px;
}
.output__title {
	font-size: 20px;
	font-weight: 600;
}
.output__text {
	font-size: 16px;
	font-weight: 600;
}
