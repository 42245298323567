.scroll {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 2px;
    min-height: 40px;
}

.scroll_title {
    margin-right: 10px;
    min-width: 100px;
    font-size: 1rem;
    font-weight: bold;
}


.scroll__options {
    overflow-x: auto;
    max-width: 350px;
    white-space: nowrap;
}

.scroll__options::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.scroll__options .item,
.scroll__options .no-filter__item {
    display: inline-block;
    text-align: center;
    font-size: 1rem;
    font-weight: 600;
    background-color: #E2E3E8;
    margin-right: 10px;
    margin-bottom: 0;
    padding: 7px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.scroll__options .no-filter__item {
    cursor: initial;
    margin-bottom: 8px;
}


@media (min-width: 768px) {
    .scroll {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 10px;
        min-height: 30px;
    }

    .scroll_title {
        margin-right: 0px;
        margin-bottom: 2px;
        min-width: 100px;
        font-size: 1.2rem;
        font-weight: bold;
    }

    .scroll__options {
        overflow-x: unset;
        max-width: unset;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        white-space: nowrap;
    }

    .scroll__options .item {
        margin-bottom: 8px;
    }
}


@media (min-width: 992px) {}


@media (min-width: 1200px) {}


@media (min-width: 1600px) {}