.form-control {
    margin: 1rem 0;
    position: relative;
}

.form-control label,
.form-control input,
.form-control textarea {
    display: block;
    box-sizing: border-box;
}

.form-control label {
    font-weight: bold;
    margin-bottom: 0.2rem;
    color: black;
    font-size: 1rem;
    font-family: inherit;
}


.form-control button {
    background-color: transparent;
    border: none;
    position: absolute;
    right: 10px;
    padding: 10px 0px;
}


.form-control button:focus {
    outline: none;
}


.form-control input,
.form-control textarea{
    width: 100%;
    height: 45px;
    font: inherit;
    border: 2px solid #D3131C;
    border-radius: 4px;
    background: #f8f8f8;
    padding: 0.25rem 0.50rem;
    box-sizing: border-box;
    font-weight: 600;
}

.form-control textarea {
    height: auto;
    max-width: 100%;
    min-width: 100%;
    min-height: 100px;
    max-height: 200px;
}


.form-control input:focus,
.form-control textarea:focus {
    outline: none;
    background: #ebebeb;
    border-color: #D3131C;
}